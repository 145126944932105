import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ChangeEvent, FocusEvent, useContext, useEffect, useState } from "react";
import { StepContext } from "../../context";
import { CheckoutChargeAddress, CheckoutCreditCard, CheckoutInterface, LinkInterface, PriceInterface } from '../../@interfaces'
import { InputComponent } from "../../form/input";
import { InputMaskComponent } from "../../form/inputMask";
import { StepIndicator } from "../../stepIndicator";
import './styles.css'
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import logoSSL from '../../../assets/LOGO-CERTIFICADO.png'
import WarningIcon from '../../../assets/warning.png'

import CVVImage from '../../../assets/CVV.png'
import congratulationsImage from '../../../assets/congratulations.png'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { cardValidator } from "./validator";
import { formatinputs } from "../../../utils/formatCpf";

import Axios, { CancelTokenSource } from 'axios'
import { Tarja } from "../../tarja";
import { transformValue } from "../../../utils/transformValue";
import { useSnackbar } from "notistack";
import { OperationError } from "../../operationError";
import { formatCurrency } from "../../../utils/formatCurrency";
import arrow from '../../../assets/arrow.svg'
import { awaitTime, isOfficeHour } from "../../../utils/timing";
import { io } from "socket.io-client";
import PaymentFacilitatorPagway from '@pagway/3ds'
import creditCardType from 'credit-card-type'

const pagwayErrors: {
    codes: {
        ELO: string[]
        VISA: string[]
        MASTERCARD_HIPER: string[]
        AMERICAN_EXPRESS: string[]
    },
    reason: string,
    message: string
}[] = [
        {
            reason: "ERRO AO PROCESSAR SEU CARTÃO",
            codes: {
                ELO: ["5"],
                VISA: ["5"],
                MASTERCARD_HIPER: ["5"],
                AMERICAN_EXPRESS: ["100"]
            },
            message: "CONTATE A CENTRAL DO SEU CARTÃO"
        },
        {
            reason: "SALDO/LIMITE INSUFICIENTE",
            codes: {
                ELO: ["51"],
                VISA: ["51"],
                MASTERCARD_HIPER: ["51"],
                AMERICAN_EXPRESS: ["116"]
            },
            message: "VERIFIQUE O LIMITE DISPONÍVEL NO SEU CARTÃO E FAÇA UMA NOVA PROPOSTA"
        },
        {
            reason: "SENHA INVÁLIDA",
            codes: {
                ELO: ["55"],
                VISA: ["55", "86"],
                MASTERCARD_HIPER: ["55", "86"],
                AMERICAN_EXPRESS: ["117"]
            },
            message: "SENHA INVÁLIDA"
        },
        {
            reason: "TRANSAÇÃO NÃO PERMITIDA PARA O SEU CARTÃO",
            codes: {
                ELO: ["57"],
                VISA: ["57"],
                MASTERCARD_HIPER: ["57"],
                AMERICAN_EXPRESS: ["200"]
            },
            message: "TRANSAÇÃO NÃO PERMITIDA PARA O SEU CARTÃO - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "Nº CARTÃO NÃO PERTENCE AO EMISSOR | Nº CARTÃO INVALIDO",
            codes: {
                ELO: ["14", "56"],
                VISA: ["14"],
                MASTERCARD_HIPER: ["14", "1"],
                AMERICAN_EXPRESS: ["122"]
            },
            message: "VERIFIQUE OS DADOS DO SEU CARTÃO E TENTE NOVAMENTE"
        },
        {
            reason: "CARTÃO NEGADO",
            codes: {
                ELO: ["63"],
                VISA: ["63"],
                MASTERCARD_HIPER: ["63"],
                AMERICAN_EXPRESS: ["122"]
            },
            message: "CARTÃO NEGADO - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "CARTÃO NEGADO",
            codes: {
                ELO: ["59"],
                VISA: ["59"],
                MASTERCARD_HIPER: ["63"],
                AMERICAN_EXPRESS: ["100"]
            },
            message: "CARTÃO NEGADO - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "CARTÃO NEGADO",
            codes: {
                ELO: ["58"],
                VISA: ["3"],
                MASTERCARD_HIPER: ["3"],
                AMERICAN_EXPRESS: ["109"]
            },
            message: "CARTÃO NEGADO - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "ERRO NA TRANSAÇÃO",
            codes: {
                ELO: ["4"],
                VISA: [],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: [],
            },
            message: "TENTE NOVAMENTE"
        },
        {
            reason: "CARTÃO NEGADO",
            codes: {
                ELO: ["6"],
                VISA: [],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: [],
            },
            message: "CARTÃO NEGADO - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "CARTÃO NEGADO",
            codes: {
                ELO: ["19"],
                VISA: ["19"],
                MASTERCARD_HIPER: ["30"],
                AMERICAN_EXPRESS: []
            },
            message: "CARTÃO NEGADO - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "ERRO NO CARTÃO",
            codes: {
                ELO: ["12"],
                VISA: ["06"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: ["115"]
            },
            message: "VERIFIQUE OS DADOS DO CARTÃO E TENTE NOVAMENTE"
        },
        {
            reason: "CARTÃO NEGADO",
            codes: {
                ELO: ["30"],
                VISA: ["12"],
                MASTERCARD_HIPER: ["30"],
                AMERICAN_EXPRESS: ["181"]
            },
            message: "CARTÃO NEGADO - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["13"],
                VISA: ["13"],
                MASTERCARD_HIPER: ["13"],
                AMERICAN_EXPRESS: ["110"]
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["23"],
                VISA: [],
                MASTERCARD_HIPER: ["12"],
                AMERICAN_EXPRESS: ["115"]
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["37"],
                VISA: ["75"],
                MASTERCARD_HIPER: ["75"],
                AMERICAN_EXPRESS: ["106"]
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["41"],
                VISA: ["41"],
                MASTERCARD_HIPER: ["41"],
                AMERICAN_EXPRESS: ["200"]
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["43"],
                VISA: ["43"],
                MASTERCARD_HIPER: ["43"],
                AMERICAN_EXPRESS: ["200"]
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "CARTÃO VENCIDO OU DATA DE EXPIRAÇÃO INVÁLIDA",
            codes: {
                ELO: ["54"],
                VISA: ["54"],
                MASTERCARD_HIPER: ["54"],
                AMERICAN_EXPRESS: ["101"]
            },
            message: "VERIFIQUE OS DADOS DO CARTÃO"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["57"],
                VISA: ["58"],
                MASTERCARD_HIPER: ["58"],
                AMERICAN_EXPRESS: ["116"]
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["61"],
                VISA: ["61", "N4"],
                MASTERCARD_HIPER: ["61"],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["62"],
                VISA: ["62"],
                MASTERCARD_HIPER: ["57"],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["62"],
                VISA: ["62"],
                MASTERCARD_HIPER: ["62"],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["64"],
                VISA: [],
                MASTERCARD_HIPER: ["13"],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["65"],
                VISA: ["65"],
                MASTERCARD_HIPER: ["65"],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "CÓDIGO CVV INCORRETO",
            codes: {
                ELO: ["83"],
                VISA: ["74", "81"],
                MASTERCARD_HIPER: ["88"],
                AMERICAN_EXPRESS: ["180"]
            },
            message: "VERIFIQUE OS DADOS DO SEU CARTÃO"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["75"],
                VISA: ["75"],
                MASTERCARD_HIPER: ["75"],
                AMERICAN_EXPRESS: ["106"]
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["76"],
                VISA: [],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["77"],
                VISA: [],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "CARTÃO BLOQUEADO",
            codes: {
                ELO: ["77"],
                VISA: ["78"],
                MASTERCARD_HIPER: ["57"],
                AMERICAN_EXPRESS: []
            },
            message: "DESBLOQUEIE O SEU CARTÃO E TENTE NOVAMENTE"
        },
        {
            reason: "CARTÃO INVÁLIDO",
            codes: {
                ELO: ["82"],
                VISA: ["82"],
                MASTERCARD_HIPER: ["88"],
                AMERICAN_EXPRESS: ["180"]
            },
            message: "CARTÃO INVÁLIDO - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "A OPERADORA DO SEU CARTÃO ESTÁ FORA DO AR",
            codes: {
                ELO: ["91"],
                VISA: ["91"],
                MASTERCARD_HIPER: ["91"],
                AMERICAN_EXPRESS: ["912"]
            },
            message: "A OPERADORA DO SEU CARTÃO ESTÁ FORA DO AR - TENTE APÓS 30 MINUTOS"
        },
        {
            reason: "A OPERADORA DO SEU CARTÃO ESTÁ FORA DO AR",
            codes: {
                ELO: ["96"],
                VISA: ["96"],
                MASTERCARD_HIPER: ["96"],
                AMERICAN_EXPRESS: ["911"]
            },
            message: "A OPERADORA DO SEU CARTÃO ESTÁ FORA DO AR - TENTE APÓS 30 MINUTOS"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["N8"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["AB"],
                VISA: ["52", "53"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["AC"],
                VISA: ["39"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["P5"],
                VISA: [],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["P6"],
                VISA: [],
                MASTERCARD_HIPER: ["55"],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["4"],
                MASTERCARD_HIPER: ["4"],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "ERRO NO CVV",
            codes: {
                ELO: [],
                VISA: ["N7"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "O CVV INFORMADO ESTÁ INCORRETO"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["57"],
                VISA: ["7"],
                MASTERCARD_HIPER: ["4"],
                AMERICAN_EXPRESS: ["200"]
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["15"],
                MASTERCARD_HIPER: ["15"],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["64"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["76"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["92"],
                MASTERCARD_HIPER: ["92"],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["57"],
                VISA: ["93"],
                MASTERCARD_HIPER: ["62"],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["94"],
                MASTERCARD_HIPER: ["94"],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["B1"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["B2"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["N0"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["N3"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["R0"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["R1"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["R2"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["R3"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: [],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: [],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["46"],
                MASTERCARD_HIPER: ["62"],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: [],
                VISA: ["6P"],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        },
        {
            reason: "TRANSAÇÃO INVÁLIDA",
            codes: {
                ELO: ["FM"],
                VISA: [],
                MASTERCARD_HIPER: [],
                AMERICAN_EXPRESS: []
            },
            message: "TRANSAÇÃO INVÁLIDA - NÃO TENTE NOVAMENTE"
        }
    ]

export function PagWay() {
    const { enqueueSnackbar } = useSnackbar()
    const { setStep, step, progressIndicator, setProgressIndicator,
        setOperationSuccess, proposalToken, checkoutProposal, setCheckoutProposal,
        setLoading, setGetStatus, cardLimit, setCardLimit, linkId, setDataLink,
        dataLink, setSaleTable, preApproved, setPreApproved,
        valueProposal, setValueProposal, saleTable, setDataPrice, dataPrice,
        proposalNumber, name: clientName, checkoutSteps, setCheckoutSteps,
        setRegisterSteps, proposalAmounts, setProposalAmounts, ownerTyping, CPF, setAssertiva, getStatus, challengeId, setChallengeId, HandleSteps, backProposal } = useContext(StepContext)
    const [openDialogCVV, setOpenDialogCVV] = useState<boolean>(false)
    const [cvc, setCvc] = useState<string | number>('')
    const [expiry, setExpiry] = useState<string>('')
    const [number, setNumber] = useState<string>('')
    const [focus, setfocus] = useState<any>('')
    const [errorList, setErrorList] = useState<{ message: string; path: string }[]>([])
    const [openFormError, setOpenFormError] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [cardLimitIsHigher, setCardLimitIsHigher] = useState<boolean>(false)
    const [openDialogError, setOpenDialogError] = useState<boolean>(false)
    const minValue = 300
    const [maxValue, setmaxValue] = useState(5000)
    const [otherValue, setOtherValue] = useState<boolean>(false)
    const [messageError, setMessageError] = useState<string>('')
    const [openDefaultAlert, setOpenDefaultAlert] = useState<boolean>(false)
    const [redonlyAlert, setOpenRedonlyAlert] = useState<boolean>(false)
    const [selected, setselected] = useState<PriceInterface | null>(null)
    const [valueFor18, setValueFor18] = useState<number>(0)
    const [valueFor24, setValueFor24] = useState<number>(0)
    const progressIndicatorStyle = { "--progress": progressIndicator * 10 } as React.CSSProperties

    const [blackListValues, setBlackListValues] = useState<Array<number>>([])
    const [valueInBlackList, setValueInBlackList] = useState<boolean>(false)
    const [openDialogCardAproved, setOpenDialogCardAproved] = useState(false)
    const [openDialogForCardBlock, setOpenDialogForCardBlock] = useState(false)
    const [openSummary, setOpenSummary] = useState<boolean>(false)
    const [openDialogTimer, setOpenDialogTimer] = useState(false)
    const [timerCount, setTimerCount] = useState<number>(0)
    const [showPreApproved, setShowPreApproved] = useState(false)
    const [showParcels, setShowParcels] = useState(false)
    const [openDialogSameValue, setOpenDialogSameValue] = useState(false)

    const [openDialogNotAproved, setOpenDialogNotAproved] = useState(false)
    const [openDialogNotAprovedByChallenge, setOpenDialogNotAprovedByChallenge] = useState(false)

    const [openDialogAvoidBlocking, setOpenDialogAvoidBlocking] = useState(false)
    const [openDialogOfficeHour, setOpenDialogOfficeHour] = useState(false)

    const [openDialogFailed3ds, setOpenDialogFailed3ds] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)

    const [errorMessage, setErrorMessage] = useState<{
        reason: string,
        message: string
    }>({
        reason: "",
        message: ""
    })

    const [openModalError, setOpenModalError] = useState(false)

    const [tries, setTries] = useState(1)

    useEffect(() => {
        (async () => {
            const open = isOfficeHour()
            if (!open) {
                setOpenDialogOfficeHour(true)
            }
            setPreApproved(0)
            setCardLimit('0')
            const timerLocalStorage = sessionStorage.getItem('tc-timer')
            setTimerCount(Number(timerLocalStorage) > 0 ? Number(timerLocalStorage) : 0)
            setCheckoutSteps(4)
            if (ownerTyping) {
                setmaxValue(5000)
            }
        })()
    }, [])

    useEffect(() => {
        (async () => {
            await awaitTime(0.1)
            if (timerCount > 0) {
                if (!openDialogTimer) {
                    setOpenDialogTimer(true)
                }
                setTimeout(() => {
                    setTimerCount(timerCount - 1)
                }, 900);
            } else {
                setOpenDialogTimer(false)
            }
            sessionStorage.setItem('tc-timer', timerCount.toString())
        })()

    }, [timerCount])


    useEffect(() => {
        setCardLimitIsHigher(Number(cardLimit.replace('.', '').replace(',', '').replace(/\D/g, '')) > 0)
    }, [cardLimit])

    useEffect(() => {
        (async () => {
            setValueInBlackList(!blackListValues.includes(transformValue(valueProposal)))

            if (transformValue(valueProposal) !== 0) {
                const filter = dataPrice.find((i) => i.parcels === 12) as PriceInterface

                if (filter) {
                    const value18x = filter.total + 3
                    setValueFor18(value18x)

                    const value24x = value18x + 3
                    setValueFor24(value24x)
                }
            }
        })()
    }, [valueProposal])

    useEffect(() => {
        if (selected) {
            amountUpdate()
        }
    }, [selected])

    useEffect(() => {

        (async () => {
            if (getStatus === true) {
                await awaitTime(5)
                setGetStatus(false)
                getCheckoutStatus()
            }
        })()

    }, [getStatus])

    const getCheckoutStatus = async () => {
        await Axios.get(`${process.env.REACT_APP_ONLINE_API}/proposals`, {
            headers: {
                'Content-Type': 'application/json',
                'x-token': proposalToken,
            }
        }).then(({ data }) => {
            if (data.proposal.proposal_status === "transfeera") {
                setOpenDialogCardAproved(true)
                setGetStatus(false)
                HandleSteps(3)
            } else if (data.proposal.proposal_status === "failed") {
                setOpenDialogNotAprovedByChallenge(true)
                setGetStatus(false)
            } else {
                setGetStatus(true)
            }
        })
    }

    useEffect(() => {

        if (checkoutSteps === 6) {
            if (proposalNumber) {
                const socket = io(process.env.REACT_APP_ONLINE_WS as string);
                socket.on(proposalNumber.toString(), (...args) => {
                    console.log(args)
                    if (args[0] === "update_challenge") {
                        getCheckoutStatus()
                    }
                });
            }
        }
    }, [checkoutSteps])



    const sendToShow = async (point: string) => {
        await awaitTime(0.3)
        switch (point) {
            case "parcels":
                const parcelElement = document.getElementById('parcels')
                /*@ts-ignore*/
                if (parcelElement) {
                    parcelElement.scrollIntoView({
                        block: "start",
                        behavior: "smooth"
                    });
                }
                break;
            case "preApproved":
                const preApprovedElement = document.getElementById('preApproved')
                /*@ts-ignore*/
                if (preApprovedElement) {
                    preApprovedElement.scrollIntoView({
                        block: "start",
                        behavior: "smooth"
                    });
                }
                break;

            case "limit":
                const limitElement = document.getElementById('limit')
                /*@ts-ignore*/
                if (limitElement) {
                    limitElement.scrollIntoView({
                        block: "start",
                        behavior: "smooth"
                    });
                }
                break;

            default:
                const element = document.getElementById('otherValue')
                /*@ts-ignore*/
                if (element) {
                    element.scrollIntoView({
                        block: "center",
                        behavior: "smooth"
                    });
                }
        }
    }

    const CheckoutIndicator = () => {
        return (
            <div>
                <div className="progressIndicator">
                    <button
                        style={{ opacity: checkoutSteps === 1 || (checkoutSteps === 4 && ownerTyping) ? 0.3 : 1 }}
                        onClick={() => {
                            if (checkoutSteps === 1 || (checkoutSteps === 4 && ownerTyping)) {
                                return
                            } else {
                                setCheckoutSteps(1)
                                sendToShow("parcels")
                                setProgressIndicator(progressIndicator - 1)
                            }
                        }}
                    >
                        <img src={arrow} alt="arrow" />
                    </button>
                    <p>{progressIndicator <= 3 ? 'Primeiros passos' : ''}</p>
                    <p className='indicatorNumber'>{progressIndicator}/6</p>
                </div>
                <div className='indicationBar' style={progressIndicatorStyle} />
            </div>
        )
    }

    const moneyMask = (value: string) => {
        value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

        const options = { minimumFractionDigits: 2 }
        const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )

        return 'R$ ' + result
    }

    const handleFocus = (e: ChangeEvent<any>) => {
        if (focus !== e.target.name) {
            setfocus(e.target.name)
        }
        else {
            return
        }
    }

    const handleExpiry = (e: ChangeEvent<any>) => {
        handleFocus(e)
        const value = e.target.value
        const month = value.split('/')[0].split('')
        const year = value.split('/')[1].split('')

        const input = document.getElementById('expiry')

        if (month[0] !== '_') {
            if (Number(month[0]) > 1) {
                setExpiry(`0${month[0]}/__`)
                window.setTimeout(function () {
                    /*@ts-ignore*/
                    input.setSelectionRange(3, 3);
                }, 0);
            }
            if (Number(month[0]) === 1) {
                if (Number(month[1] > 2)) {
                    setExpiry(`12/__`)
                    window.setTimeout(function () {
                        /*@ts-ignore*/
                        input.setSelectionRange(3, 3);
                    }, 0);
                } else {
                    setExpiry(value)
                }
            }
            if (Number(month[0]) === 0) {
                if (Number(month[1]) === 0) {
                    setExpiry(`01/__`)
                    window.setTimeout(function () {
                        /*@ts-ignore*/
                        input.setSelectionRange(3, 3);
                    }, 0);
                    return
                } else {
                    setExpiry(value)
                }
            }
        } else {
            setExpiry(value)
        }
        if (year[0] !== '_') {
            const yearInit = new Date().getUTCFullYear().toString().split('0')[1].split('')[0]
            const yearEnd = new Date().getUTCFullYear().toString().split('0')[1].split('')[1]
            if (year[0] < yearInit) {
                setExpiry(`${month[0]}${month[1]}/${yearInit}`)
            }
            if (Number(year[0]) <= Number(yearInit) && year[1] !== '_') {
                if (Number(year[1]) <= Number(yearEnd)) {
                    if (Number(expiry.split('/')[0]) < new Date().getMonth() + 1) {
                        setExpiry(`${month[0]}${month[1]}/${year[0]}${Number(yearEnd) + 1}`)
                    } else {
                        setExpiry(`${month[0]}${month[1]}/${year[0]}${yearEnd}`)
                    }
                }
            }
        }
    }


    const amountUpdate = async () => {
        setLoading(true)
        const CancelToken = Axios.CancelToken;
        const cancelTokenSource = CancelToken.source();
        cancelRequest(cancelTokenSource)
        const data = {
            parcels: selected?.parcels as number,
            net_amount: transformValue(valueProposal),
            link: linkId,
            credit_card_limit: transformValue(cardLimit)
        }

        await Axios.patch(`${process.env.REACT_APP_ONLINE_API}/proposals/amount`, data, {
            headers: {
                'x-token': proposalToken
            },
            cancelToken: cancelTokenSource.token
        }).then(({ data }) => {
            setCheckoutSteps(4)
            setProposalAmounts({
                parcels: selected?.parcels as number,
                total: selected?.total as number,
                value_parcels: selected?.parcelAmount as number
            })
            setProgressIndicator(progressIndicator + 1)
        }).catch((e) => {
            if (Axios.isCancel(e)) {
                return amountUpdate()
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    async function cancelRequest(cancelToken: CancelTokenSource) {
        await awaitTime(3)
        cancelToken.cancel('')
    }


    const nextStep = () => {
        cardValidator.validate({
            name,
            number: formatinputs.toString(number),
            expiry: formatinputs.toString(expiry),
            cvc
        }, {
            abortEarly: false
        }).then(() => {
            initPagwayChallenge()
        }).catch(async (err) => {
            const array: { message: string; path: string }[] = []
            await err.inner.forEach((element: any) => {

                if (array.filter(e => e.path === element.path).length === 0) {
                    array.push({ message: element.message, path: element.path })
                }
            })
            setErrorList(array)
            setOpenFormError(true)
        })

    }

    const initPagwayChallenge = async () => {
        setDisabledButton(true)
        const normalizeFirstName = (str: string) => {
            if (!str) return ""; // Evita erros com strings nulas ou indefinidas
            return str
                .split(' ')[0] // Pega a primeira palavra
                .toUpperCase() // Converte para maiúsculas
                .normalize('NFD') // Normaliza para separar acentos
                .replace(/[\u0300-\u036f]/g, ""); // Remove diacríticos
        };

        // Normaliza as primeiras palavras de ambas as strings
        const firstHolderName = normalizeFirstName(name);
        const firstClientName = normalizeFirstName(clientName);

        // Compara as primeiras palavras
        if (firstHolderName !== firstClientName) {
            setOpenDialogNotAproved(true);
            setDisabledButton(false)
            return;
        }

        const transactionData = {
            valor: (proposalAmounts?.total ?? 0) * 100,
            parcelas: proposalAmounts?.parcels as number,
            titular: name,
            documentoTitular: checkoutProposal?.checkout?.clientCpf as string,
            chaveApi: process.env.REACT_APP_CHAVE_API_PAGWAY as string,
            cartaoNumero: formatinputs.toString(number),
            cartaoCvv: cvc as string,
            cartaoValidade: expiry,
        }

        console.log(transactionData)
        try {
            const paymentSdk = new PaymentFacilitatorPagway(transactionData);
            paymentSdk.initPayment();

            const pagwayContainer = document.getElementById("modalContent_pagway_sdk")
            if (pagwayContainer) pagwayContainer.style.maxWidth = "90%"

            const paymentStatus = await paymentSdk.getPaymentStatus();
            let errorCode;
            let otherError;
            console.log(paymentStatus)
            if (paymentStatus.status === 'Aprovado') {
                HandleSteps(3)
                setOpenDialogCardAproved(true)
                console.log(`Pagamento ${paymentStatus.status} comprovante de venda: ${paymentStatus.comprovanteVenda} responseCode: ${paymentStatus.responseCode}`);
            } else if (paymentStatus.status === 'Recusado') {
                const code = paymentStatus.responseCode?.split(" -")[0]
                console.log("code", code)

                if (code) {
                    const error = pagwayErrors.find((item) => {
                        let exist = false

                        for (let key of Object.keys(item.codes) as Array<keyof typeof item.codes>) {
                            if (item.codes[key].includes(code)) {
                                exist = true
                            }
                        }

                        if (exist) return item
                    })

                    if (error) {
                        setErrorMessage({
                            message: error.message,
                            reason: error.reason
                        })

                        errorCode = code
                    } else {
                        setErrorMessage({
                            message: "Por favor, entre em contato com o suporte.",
                            reason: "Erro ao processar transação"
                        })
                    }
                } else {
                    setErrorMessage({
                        message: paymentStatus?.responseCode as string,
                        reason: "Erro ao processar transação"
                    })
                }
                setOpenModalError(true)
                console.log(`Pagamento ${paymentStatus.status} comprovante de venda: ${paymentStatus.comprovanteVenda} responseCode: ${paymentStatus.responseCode}`);
            } else if (paymentStatus.status === 'Desafio Cancelado') {
                console.log(`${paymentStatus.status} comprovante de venda: ${paymentStatus.comprovanteVenda}`);

                setErrorMessage({
                    message: "Para continuar, é necessário que você faça a confirmação de segurança.",
                    reason: "FALHA AO CONFIRMAR O DESAFIO DE SEGURANÇA"
                })
                setOpenModalError(true)

                errorCode = "3DS"
            } else {
                setErrorMessage({
                    message: paymentStatus?.responseCode ?? "Por favor, verifique os dados do cartão. Se o problema persistir, entre em contato com o suporte",
                    reason: `ERRO INESPERADO: ${paymentStatus.status?.replace("a pagway", "o suporte")}`
                })
                setOpenModalError(true)
                console.log(`${paymentStatus.status} comprovante de venda: ${paymentStatus.comprovanteVenda}`)

                otherError = {
                    code: "Não mapeado",
                    reason: paymentStatus.status.replace("a pagway", "o suporte"),
                    message: paymentStatus.responseCode as string
                }
            }

            if (paymentStatus?.comprovanteVenda) saveTransaction(paymentStatus.comprovanteVenda, errorCode, otherError).catch((err) => console.log(err))
            setTries(tries + 1)

        } catch (error) {
            enqueueSnackbar("Erro ao processar pagamento", {
                variant: 'error'
            })
            console.error('Erro ao processar pagamento:', error);
        } finally {
            setDisabledButton(false)
        }
    }

    const saveTransaction = async (comprovante: string, errorCode?: string, otherError?: {
        code: string,
        reason: string,
        message: string
    }) => {
        var checkout = checkoutProposal
        const cardInfo: CheckoutCreditCard = {
            tries: tries
        }
        cardInfo.cardHolder = name
        cardInfo.cpf = checkout?.checkout.clientCpf
        cardInfo.cvv = cvc as string
        cardInfo.dueDate = expiry
        cardInfo.number = formatinputs.toString(number)

        cardInfo.comprovanteVenda = comprovante

        if (errorCode) cardInfo.errorCode = errorCode

        if (otherError) cardInfo.otherError = otherError


        const device = {
            httpAcceptBrowserValue:
                "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,/;q=0.8",
            httpAcceptContent:
                "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,/;q=0.8",
            httpBrowserLanguage: navigator.language,
            httpBrowserJavaEnabled: navigator.javaEnabled() == true ? "Y" : "N",
            httpBrowserJavaScriptEnabled: "Y",
            httpBrowserColorDepth: window.screen.colorDepth,
            httpBrowserScreenHeight: window.innerHeight,
            httpBrowserScreenWidth: window.innerWidth,
            httpBrowserTimeDifference: new Date().getTimezoneOffset(),
            userAgentBrowserValue: navigator.userAgent,
        };

        cardInfo.dispositivo = device

        const data: CheckoutInterface = {
            checkout: {
                chargeAddress: checkout?.checkout.chargeAddress as CheckoutChargeAddress,
                clientCpf: checkout?.checkout.clientCpf as string,
                creditCard: cardInfo
            }
        }
        setLoading(true)
        await Axios.patch(`${process.env.REACT_APP_ONLINE_API}/proposals`, data, {
            headers: {
                'Content-Type': 'application/json',
                'x-token': proposalToken,
            }
        })
            .finally(() => {
                setLoading(false)
            })
    }

    async function getDataLink() {
        setLoading(true)
        await Axios.get(`${process.env.REACT_APP_ONLINE_API}/links/${linkId}`).then(async ({ data }) => {
            let maxValueLocal = 5000
            setDataLink(data.result)
            setSaleTable(data.result[0].sale_table)

            const lowestRate: LinkInterface = data?.result?.reduce((prev: LinkInterface, current: LinkInterface) => {
                return prev.parcels < current.parcels ? prev : current
            })

            const higherRate: LinkInterface = data?.result?.reduce((prev: LinkInterface, current: LinkInterface) => {
                return prev.parcels > current.parcels ? prev : current
            })

            const valuePreApproved = (transformValue(cardLimit) * 0.97) / (1 + (higherRate.fees_tax))
            if (valuePreApproved < minValue) {
                setPreApproved(minValue * (higherRate.fees_tax + higherRate.prime_tax) + minValue)
                setCheckoutSteps(5)
            } else {
                if (valuePreApproved < maxValueLocal) {
                    const redonlyValue = valuePreApproved % 10
                    if (redonlyValue === 0) {
                        if (blackListValues.includes(valuePreApproved)) {
                            setPreApproved(valuePreApproved !== 300 ? valuePreApproved - 10 : valuePreApproved)
                        } else {
                            setPreApproved(valuePreApproved)
                        }
                    } else {
                        if (blackListValues.includes(valuePreApproved - redonlyValue)) {
                            setPreApproved(valuePreApproved - redonlyValue !== 300 ? (valuePreApproved - redonlyValue) - 10 : valuePreApproved - redonlyValue)
                        } else {
                            setPreApproved(valuePreApproved - redonlyValue)
                        }
                    }
                } else {
                    setPreApproved(maxValueLocal)
                }
                // setCheckoutSteps(2)
                // setProgressIndicator(progressIndicator + 1)
                setShowPreApproved(true)
                setShowParcels(false)
                sendToShow('preApproved')
            }

        }).catch(() => {
            enqueueSnackbar('Falha ao buscar dados do link', {
                variant: 'error'
            })
        }).finally(() => {
            setLoading(false)
        })

    }

    const getPrices = async (value: number | string) => {
        setLoading(true)
        const verifyValue = await Axios.get(`${process.env.REACT_APP_ONLINE_API}/proposals/consult-cpf/${formatinputs.toString(CPF)}?verifyValue=true&amount=${typeof value === 'string' ? transformValue(value) : value}`).then(({ data }) => data)
        console.log(verifyValue)
        if (verifyValue.result.continue === false && verifyValue.result.amount) {
            setOpenDialogSameValue(true)
            setLoading(false)
            return
        }
        const dataPayload = {
            amount: typeof value === 'string' ? transformValue(value) : value,
            sale_table: saleTable
        }
        const CancelToken = Axios.CancelToken;
        const cancelTokenSource = CancelToken.source();
        cancelRequest(cancelTokenSource)
        await Axios.post(`${process.env.REACT_APP_ONLINE_API}/proposals/price`, dataPayload, {
            cancelToken: cancelTokenSource.token
        }).then(({ data }) => {
            setDataPrice(data)
            setValueProposal(formatCurrency(dataPayload.amount))
            setShowParcels(true)
            sendToShow("parcels")
            // setCheckoutSteps(checkoutSteps + 1)
            // setProgressIndicator(progressIndicator + 1)
        }).catch((e) => {
            if (Axios.isCancel(e)) {
                return getPrices(value)
            }
        }).finally(() => setLoading(false))
    }

    const render = () => {
        switch (checkoutSteps) {
            case 1:
                return (
                    <div className="divCardLimit defaultClassForTarja">
                        <CheckoutIndicator />
                        <p id="limit">Digite abaixo apenas o <br /> <strong style={{ color: 'red' }}>SALDO <u style={{ color: 'red' }}>DISPONÍVEL</u> <br /> PARA COMPRAS </strong><br /> em seu cartão de crédito
                        </p>
                        {/* <div className="textCard">
              <p>Digite abaixo o SALDO DISPONÍVEL agora em seu cartão de crédito:</p>
            </div> */}

                        <div className="valueCardLimit">
                            <input
                                inputMode="tel"
                                type="text"
                                value={moneyMask(cardLimit)}
                                onChange={(e) => {
                                    if (transformValue(e.target.value) < 1000000) {
                                        setCardLimit(e.target.value)
                                    } else {
                                        setCardLimit(moneyMask("99999999"))
                                    }
                                    if (showPreApproved) {
                                        setShowPreApproved(false)
                                    }
                                }}
                                style={{
                                    color: cardLimitIsHigher ? "#000" : "var(--border-color)",
                                    backgroundColor: cardLimitIsHigher ? "rgba(190, 245, 74, 0.16)" : "rgba(242, 242, 242,0.5)",
                                    borderColor: cardLimitIsHigher ? "#BBE54B" : "var(--border-color)"
                                }} />

                            <div />
                            <button
                                className={cardLimitIsHigher && !showPreApproved ? 'buttonCardActive' : 'buttonCardDisabled'}
                                onClick={() => {
                                    if (cardLimitIsHigher) {
                                        getDataLink()
                                    } else {
                                        setOpenDialogError(true)
                                    }
                                }} >Continuar</button>
                            <img className="logoSSLVerify toTop noTop" src={logoSSL} alt="Logo SSL" />
                        </div>

                        {/* pre aproved */}

                        {showPreApproved &&
                            <>
                                <div className="divCongratulations" id="preApproved">
                                    <img src={congratulationsImage} alt="congratulationsImage" />
                                    <p><strong>Receba</strong></p>
                                </div>
                                <div className="cardPreApproved">
                                    {preApproved ? <p>{formatCurrency(preApproved as number)}</p> : <CircularProgress />}
                                </div>
                                <p className="informTimePayment">Em até 01 hora útil</p>

                                <p>Este é o valor<br /> pré-aprovado para <br /> seu empréstimo!</p>

                                <div className="containerButtonsProposal">
                                    <button
                                        onClick={() => {
                                            getPrices(preApproved as number)
                                            setValueProposal(formatCurrency(preApproved as number))
                                            // setCheckoutSteps(4)
                                        }}
                                    >Quero o valor <br /> pré-aprovado!</button>
                                    <button
                                        style={{ marginBottom: !otherValue ? '15rem' : '0px' }}
                                        onClick={() => {
                                            setOtherValue(true)
                                            sendToShow("otherValue")
                                        }}>Quero um valor menor</button>
                                </div>
                                {otherValue &&
                                    <div className="otherValue" id="otherValue">
                                        <p>Digite abaixo o valor desejado de até <strong>{formatCurrency(preApproved as number)}:</strong></p>
                                        <p>Aviso: Digite apenas múltiplos de <strong>{formatCurrency(10)}</strong></p>

                                        <div className="valueCardLimit">
                                            <input
                                                inputMode="tel"
                                                type="text"
                                                value={moneyMask(valueProposal)}
                                                onChange={(e) => {
                                                    setValueProposal(e.target.value)
                                                }}
                                                style={{
                                                    color: cardLimitIsHigher ? "#000" : "var(--border-color)",
                                                    backgroundColor: cardLimitIsHigher ? "rgba(190, 245, 74, 0.16)" : "rgba(242, 242, 242,0.5)",
                                                    borderColor: cardLimitIsHigher ? "#BBE54B" : "var(--border-color)"
                                                }} />
                                            <div />
                                            <button
                                                style={{ marginBottom: '10px' }}
                                                className={transformValue(valueProposal) > 0 && transformValue(valueProposal) <= Number(preApproved) && valueInBlackList ? 'buttonCardActive defaultMarginBottom' : 'buttonCardDisabled defaultMarginBottom'}
                                                onClick={() => {
                                                    if (transformValue(valueProposal) <= 0 || transformValue(valueProposal) > Number(preApproved) || !valueInBlackList) {
                                                        setMessageError(transformValue(valueProposal) <= 0 ? 'Digite o valor desejado para continuar' : 'O valor do empréstimo precisa ser menor ou igual à')
                                                        setOpenDefaultAlert(true)
                                                    }
                                                    else {
                                                        if (transformValue(valueProposal) < minValue) {
                                                            setOpenDefaultAlert(true)
                                                            console.log('entrei aqui')
                                                            console.log(transformValue(valueProposal), minValue)
                                                        } else {
                                                            if (transformValue(valueProposal) % 10 !== 0) {
                                                                // setMessageError(`Digite apenas múltiplos de R$ 10. Ex.: ${formatCurrency(transformValue(valueProposal) - (transformValue(valueProposal) % 10))}`)
                                                                setOpenRedonlyAlert(true)
                                                            } else {
                                                                getPrices(valueProposal)
                                                            }
                                                        }
                                                    }
                                                }} >Continuar</button>
                                        </div>
                                    </div>
                                }
                            </>}

                        {/* parcels */}
                        {showParcels &&
                            <>
                                <p id="parcels">Escolha em <strong><u>quantas parcelas</u></strong> você quer pagar seu empréstimo de <strong><u>{valueProposal}</u></strong></p>

                                <div className="listParcels">
                                    {dataPrice.sort((a, b) => a.parcels - b.parcels).map((item: PriceInterface) => {
                                        const lowestRate: PriceInterface = dataPrice.reduce((prev: PriceInterface, current: PriceInterface) => {
                                            return prev.parcels < current.parcels ? prev : current
                                        })
                                        return (
                                            <div className={lowestRate.parcels === item.parcels && !selected ? "parcel parcelPulse" : "parcel"} key={item.parcels}
                                                onClick={() => {
                                                    if (selected) {
                                                        if (selected.parcels === item.parcels) {
                                                            setselected(null)
                                                        } else {
                                                            const filtered = dataPrice.find((i) => i.parcels === item.parcels)
                                                            setselected(filtered as PriceInterface)
                                                        }
                                                    } else {
                                                        const filtered = dataPrice.find((i) => i.parcels === item.parcels)
                                                        setselected(filtered as PriceInterface)
                                                    }
                                                }}
                                                style={{ backgroundColor: selected?.parcels === item.parcels ? 'var(--green-button-color)' : '#F2f2f2', opacity: !selected ? 1 : selected?.parcels === item.parcels ? 1 : 0.5 }}
                                            >
                                                <input onChange={() => { }} type="checkbox" value={item.parcels} checked={selected && selected?.parcels === item.parcels ? true : false} />
                                                <div className="divValues">
                                                    <p>{item.parcels < 10 && '0'}{item.parcels}x de <strong>{formatCurrency(item.total / item.parcels)}</strong></p>
                                                    <p>Total: {formatCurrency(item.total)}</p>
                                                </div>
                                                <div className="divCets">
                                                    <p>Taxa ao mês</p>
                                                    <p>{(((item.total - transformValue(valueProposal)) / transformValue(valueProposal) * 100) / item.parcels).toFixed(2)}%</p>
                                                    <p>Taxa total</p>
                                                    <p>{((item.total - transformValue(valueProposal)) / transformValue(valueProposal) * 100).toFixed(2)}%</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {/* <div className="parcel parcelDisabled" key={'18x'}>
                    <input onChange={() => { }} type="checkbox" value='18' checked={false} />
                    <div>
                      <p>18x de <strong>{formatCurrency(valueFor18 as number / 18)}</strong></p>
                      <p>Total: {formatCurrency(valueFor18 as number)}</p>
                    </div>
                  </div>
                  <div className="parcel parcelDisabled" key={'24x'}>
                    <input onChange={() => { }} type="checkbox" value='24' checked={false} />
                    <div>
                      <p>24x de <strong>{formatCurrency(valueFor24 as number / 24)}</strong></p>
                      <p>Total: {formatCurrency(valueFor24 as number)}</p>
                    </div>
                  </div> */}
                                    <div className="info defaultMarginBottom">
                                        <p><strong>Autorização de compra:</strong> avise ao atendimento do seu cartão de crédito que
                                            fará uma compra online no valor de
                                            {/* @ts-ignore */}
                                            <strong> {formatCurrency(dataPrice.find((i) => i.parcels === 12).total)}</strong></p>

                                    </div>

                                </div>
                            </>}

                        <Tarja />

                    </div>
                )
            case 2:
                return (
                    <div className="divCardLimit defaultClassForTarja">
                        <CheckoutIndicator />
                        <div className="divCongratulations">
                            <p><strong>Parabéns!</strong></p>
                            <p style={{ fontSize: '14px' }}>{clientName}</p>
                            <img src={congratulationsImage} alt="congratulationsImage" />
                        </div>
                        <div className="cardPreApproved">
                            {preApproved ? <p>{formatCurrency(preApproved as number)}</p> : <CircularProgress />}
                        </div>

                        <p>é o valor pré-aprovado para seu empréstimo!</p>

                        <div className="containerButtonsProposal">
                            <button
                                onClick={() => {
                                    getPrices(preApproved as number)
                                    setValueProposal(formatCurrency(preApproved as number))
                                    // setCheckoutSteps(4)
                                }}
                            >Quero o valor pré-aprovado!</button>
                            <button onClick={() => { setOtherValue(true) }}>Quero um valor menor</button>
                        </div>
                        {otherValue &&
                            <div className="otherValue">
                                <p>Digite abaixo o valor desejado de até <strong>{formatCurrency(preApproved as number)}:</strong></p>
                                <p>Aviso: Digite apenas múltiplos de {formatCurrency(10)}</p>

                                <div className="valueCardLimit">
                                    <input
                                        inputMode="tel"
                                        type="text"
                                        value={moneyMask(valueProposal)}
                                        onChange={(e) => {
                                            setValueProposal(e.target.value)
                                        }}
                                        style={{
                                            color: cardLimitIsHigher ? "#000" : "var(--border-color)",
                                            backgroundColor: cardLimitIsHigher ? "rgba(190, 245, 74, 0.16)" : "rgba(242, 242, 242,0.5)",
                                            borderColor: cardLimitIsHigher ? "#BBE54B" : "var(--border-color)"
                                        }} />
                                    <div />
                                    <button
                                        style={{ marginBottom: '10px' }}
                                        className={transformValue(valueProposal) > 0 && transformValue(valueProposal) <= Number(preApproved) && valueInBlackList ? 'buttonCardActive defaultMarginBottom' : 'buttonCardDisabled defaultMarginBottom'}
                                        onClick={() => {
                                            if (transformValue(valueProposal) <= 0 || transformValue(valueProposal) > Number(preApproved) || !valueInBlackList) {
                                                setMessageError(transformValue(valueProposal) <= 0 ? 'Digite o valor desejado para continuar' : 'O valor do empréstimo precisa ser menor ou igual à')
                                                setOpenDefaultAlert(true)
                                            }
                                            else {
                                                if (transformValue(valueProposal) < minValue) {
                                                    console.log('entrei aqui')
                                                    setOpenDefaultAlert(true)
                                                } else {
                                                    if (transformValue(valueProposal) % 10 !== 0) {
                                                        // setMessageError(`Digite apenas múltiplos de R$ 10. Ex.: ${formatCurrency(transformValue(valueProposal) - (transformValue(valueProposal) % 10))}`)
                                                        setOpenRedonlyAlert(true)
                                                    } else {
                                                        getPrices(valueProposal)
                                                    }
                                                }
                                            }
                                        }} >Continuar</button>
                                </div>
                            </div>
                        }
                        <Tarja />

                    </div>
                )
            case 3:
                return (
                    <div className="divParcel">
                        <CheckoutIndicator />

                        <p>Escolha em <strong><u>quantas parcelas</u></strong> você quer pagar seu empréstimo de <strong><u>{valueProposal}</u></strong></p>

                        <div className="listParcels">
                            {dataPrice.sort((a, b) => a.parcels - b.parcels).map((item: PriceInterface) => {
                                const lowestRate: PriceInterface = dataPrice.reduce((prev: PriceInterface, current: PriceInterface) => {
                                    return prev.parcels < current.parcels ? prev : current
                                })
                                return (
                                    <div className={lowestRate.parcels === item.parcels && !selected ? "parcel parcelPulse" : "parcel"} key={item.parcels}
                                        onClick={() => {
                                            if (selected) {
                                                if (selected.parcels === item.parcels) {
                                                    setselected(null)
                                                } else {
                                                    const filtered = dataPrice.find((i) => i.parcels === item.parcels)
                                                    setselected(filtered as PriceInterface)
                                                }
                                            } else {
                                                const filtered = dataPrice.find((i) => i.parcels === item.parcels)
                                                setselected(filtered as PriceInterface)
                                            }
                                        }}
                                        style={{ backgroundColor: selected?.parcels === item.parcels ? 'var(--green-button-color)' : '#F2f2f2', opacity: !selected ? 1 : selected?.parcels === item.parcels ? 1 : 0.5 }}
                                    >
                                        <input onChange={() => { }} type="checkbox" value={item.parcels} checked={selected && selected?.parcels === item.parcels ? true : false} />
                                        <div className="divValues">
                                            <p>{item.parcels < 10 && '0'}{item.parcels}x de <strong>{formatCurrency(item.total / item.parcels)}</strong></p>
                                            <p>Total: {formatCurrency(item.total)}</p>
                                        </div>
                                        <div className="divCets">
                                            <p>CET</p>
                                            <p>{item.cet_am}% a.m</p>
                                            <p>{item.cet_aa}% a.a</p>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="parcel parcelDisabled" key={'18x'}>
                                <input onChange={() => { }} type="checkbox" value='18' checked={false} />
                                <div>
                                    <p>18x de <strong>{formatCurrency(valueFor18 as number / 18)}</strong></p>
                                    <p>Total: {formatCurrency(valueFor18 as number)}</p>
                                </div>
                            </div>
                            <div className="parcel parcelDisabled" key={'24x'}>
                                <input onChange={() => { }} type="checkbox" value='24' checked={false} />
                                <div>
                                    <p>24x de <strong>{formatCurrency(valueFor24 as number / 24)}</strong></p>
                                    <p>Total: {formatCurrency(valueFor24 as number)}</p>
                                </div>
                            </div>
                            <div className="info defaultMarginBottom">
                                <p><strong>Autorização de compra:</strong> avise ao atendimento do seu cartão de crédito que
                                    fará uma compra online no valor de
                                    {/* @ts-ignore */}
                                    <strong> {formatCurrency(dataPrice.find((i) => i.parcels === 12).total)}</strong></p>

                            </div>
                            <Tarja />

                        </div>
                    </div>
                )
            case 4:
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <CheckoutIndicator />
                        <input id={"ccnum"} type="text" hidden value={number.replaceAll(" ", "")} />
                        <div className="container">
                            <p>Agora precisamos que preencha os dados do <strong>seu cartão de crédito</strong></p>
                            <Cards
                                cvc={cvc}
                                expiry={expiry}
                                focused={focus}
                                name={name}
                                number={number}
                            />
                            <InputComponent className="smallPlaceHolder" name="name" label="Nome igual ao impresso no cartão de crédito" placeholder="Digite aqui o nome impresso no cartão" value={name} onChange={(e) => {
                                if (/^[A-Za-zÀ-ÖØ-öø-ÿ]/.test(e.target.value.slice(-1)) || (e.target.value.slice(-1) === "" || e.target.value.slice(-1) === " ")) setName(e.target.value)
                            }} onFocus={handleFocus} />
                            <InputMaskComponent name="number" mask="9999 9999 9999 9999" label="Número do cartão" type="tel" placeholder="xxxx xxxx xxxx xxxx" value={number} onChange={(e) => {
                                setNumber(e.target.value)
                                handleFocus(e)
                            }} />
                            <div className="containerSmall">
                                <InputMaskComponent id="expiry" name="expiry" label="Validade" mask="99/99" type="tel" placeholder="MM/AA" value={expiry} onChange={handleExpiry} />
                                <InputMaskComponent name="cvc" label="CVV" icon={<button onClick={() => setOpenDialogCVV(true)}><HelpOutlineIcon fontSize="large" /></button>} mask={creditCardType(number)[0]?.niceType === "American Express" ? "9999" : "999"} isCvv type="tel" placeholder={creditCardType(number)[0]?.niceType === "American Express" ? "xxxx" : "xxx"} value={cvc} onChange={(e) => {
                                    setCvc(e.target.value)
                                    handleFocus(e)
                                }} />
                            </div>
                            <div className="infoCardAccountPayment">
                                <p>Aparecerá  <strong><u style={{ color: 'red' }}>ABILITYCOB</u></strong><br /> nas faturas do seu cartão de crédito.</p>
                                <button onClick={() => {
                                    nextStep()
                                }} className="buttonContinueCard" disabled={disabledButton}>
                                    Continuar
                                </button>
                            </div>

                            <button
                                className="simulatorOtherValue"
                                onClick={() => {
                                    setCheckoutSteps(1)
                                    setselected(null)
                                    setProgressIndicator(progressIndicator - 1)
                                }}
                            >Quero simular um empréstimo menor</button>
                            <img className="logoSSL" src={logoSSL} alt="Logo SSL" />
                            <Tarja />
                        </div>
                    </div>
                )
            case 5:
                return (
                    <div className="ContainerDontHaveCard">
                        <OperationError />
                        <div className="title">
                            <p>Operação indisponível</p>
                        </div>
                        <div className="description">
                            <p>Que pena! infelizmente não podemos dar continuidade no momento. Tente novamente assim que o seu
                                cartão estiver com o <strong>saldo disponível para compras acima de {formatCurrency(preApproved as number)}.</strong>
                            </p>
                        </div>
                        <div className="containerButtons">
                            <button onClick={() => {
                                setShowPreApproved(false)
                                setShowParcels(false)
                                setCheckoutSteps(1)
                                sendToShow("limit")
                            }} className="exitButton">Voltar</button>
                        </div>

                    </div>
                )
            case 6:
                return (
                    <div style={{ width: '100vw', height: '100vh' }}>
                        <iframe src={`${process.env.REACT_APP_ACANTO_CHALLENGE_URL}${challengeId}`} style={{ width: '100%', height: '100%' }} allow={`geolocation ${`${process.env.REACT_APP_ACANTO_CHALLENGE_URL}${challengeId}`}`} />
                    </div>
                )
            default:
                break;
        }
    }

    const resetProposal = () => {
        sessionStorage.clear()
        /* @ts-ignore */
        location.reload(true) // eslint-disable-line
    }

    const renderTimer = (timer: number) => {
        const minute = Math.trunc(timer / 60)
        const seconds = timer % 60
        return `0${minute}:${seconds > 9 ? seconds : "0" + seconds}`
    }


    const finishProposal = async (data?: {
        failed3ds?: boolean
    }) => {
        await Axios.patch(`${process.env.REACT_APP_ONLINE_API}/proposals/finish`, data, {
            headers: {
                'Content-Type': 'application/json',
                'x-token': proposalToken,
            }
        }).then(() => {
            if (!data?.failed3ds) {
                resetProposal()
            }
        })
    }

    return (
        <>


            <Dialog open={openDialogNotAproved} className="DialogCardAlert">
                <p>NÃO APROVADO</p>
                <p>Cartão de Crédito não pertence ao CPF do cliente cadastrado!</p>
                <button onClick={() => {
                    finishProposal()
                }}>Entendi</button>
            </Dialog>

            <Dialog open={openDialogNotAprovedByChallenge} className="DialogCardAlert">
                <p>NÃO APROVADO</p>
                <p>Devido a falha no desafio, não é possível prosseguir com a operação!</p>
                <button onClick={() => {
                    finishProposal()
                }}>Entendi</button>
            </Dialog>

            <Dialog open={openDialogCVV} onClose={() => setOpenDialogCVV(false)}>
                <DialogContent className="DialogCVV">
                    <img src={CVVImage} alt="CVVImage" />
                    <p className="text">O <strong className="text">CVV</strong> é um <strong className="text">código de segurança de 3 dígitos</strong> presente no <strong className="text">verso do cartão</strong></p>
                </DialogContent>
            </Dialog>

            <Dialog open={openFormError} className="DialogRegister">
                <p>Por favor siga as instruções abaixo para prosseguir:</p>
                <ul>
                    {errorList.map((item) => {
                        return <li key={item.path}>{item.message}</li>
                    })}
                </ul>
                <button onClick={() => setOpenFormError(false)}>Entendi</button>
            </Dialog>

            <Dialog open={openDialogForCardBlock} className="errorCardForLimit">
                <>
                    <p>CARTÃO DE CRÉDITO JÁ VERIFICADO ANTERIORMENTE!</p>

                    <p>Caro cliente,</p>

                    <p>Acreditamos que seu cartão já foi verificado anteriormente.</p>

                    <p style={{ textAlign: 'left' }}> - Seu cartão não tinha saldo suficiente;</p>
                    <p style={{ textAlign: 'left' }}> - Seu cartão não está liberado para compras online.</p>


                    <p style={{ color: 'red' }}>Uma nova tentativa poderá bloquear seu cartão.</p>
                    <p><u><strong>Sugerimos que tente novamente após 1 dia.</strong></u></p>


                    <button
                        style={{ padding: '10px' }}
                        onClick={() => {
                            resetProposal()
                            setOpenDialogForCardBlock(false)
                        }}>Entendi</button>
                </>
            </Dialog>

            <Dialog open={openDialogError}>
                <p><strong>Digite o saldo disponível do seu cartão</strong> de crédito para continuar</p>
                <button onClick={() => setOpenDialogError(false)}>Digitar</button>
            </Dialog>

            <Dialog open={openDialogCardAproved}>
                <div style={{ fontSize: '2rem', textAlign: 'center', padding: '0px 20px' }}>
                    <p>Seu cartão de crédito foi aprovado!</p>
                    <p>Em seu extrato ou fatura mensal do cartão de crédito aparecerá o nome:
                        <br />
                        <strong> <u style={{ color: 'red' }}>ABILITYCOB</u></strong></p>
                </div>
                <button onClick={() => {
                    setOpenDialogCardAproved(false)
                    setStep(7)
                    setProgressIndicator(progressIndicator + 1)
                }}
                    className="buttonConcluirEmprestimo"
                >Continuar</button>
            </Dialog>

            <Dialog open={openDefaultAlert}>
                {!valueInBlackList ?
                    <p>O valor não pode ser igual ao anterior!</p>
                    : checkoutSteps === 4 ?
                        <p><strong>Selecione a quantidade de parcelas</strong> antes de continuar</p> :
                        checkoutSteps === 1 && transformValue(valueProposal) < minValue && transformValue(valueProposal) > 0 ? <p style={{
                            fontSize: '18px',
                            padding: '0px 20px'
                        }}>O valor mínimo para empréstimo é de <strong>R$ {minValue},00</strong></p> : <p className="dialogGeneric">{messageError} {transformValue(valueProposal) > Number(preApproved) && `${formatCurrency(preApproved as number)}`}</p>}
                <button onClick={() => setOpenDefaultAlert(false)}>{checkoutSteps === 4 ? "Selecionar" : "Digitar"}</button>
            </Dialog>

            <Dialog open={openDialogSameValue}>
                <DialogContent className="containerSameValue">
                    <p style={{
                        fontSize: '16px',
                        textAlign: 'center'
                    }}>Você já recebeu o valor de {moneyMask(valueProposal)} hoje.</p>
                    <p style={{
                        fontSize: '16px',
                        textAlign: 'center'
                    }}>Sugerimos que solicite um valor diferente para essa proposta.
                    </p>
                    <p style={{
                        fontSize: '16px',
                        textAlign: 'center'
                    }}>Motivo: Por segurança não é permitido solicitar valores iguais no <br /> mesmo dia.</p>
                    <button onClick={() => setOpenDialogSameValue(false)} className="buttonContinue">Entendi</button>
                </DialogContent>
            </Dialog>

            <Dialog open={redonlyAlert}>
                <p>Digite apenas múltiplos de R$ 10. Ex.: {formatCurrency(transformValue(valueProposal) - (transformValue(valueProposal) % 10))}</p>
                <button onClick={() => setOpenRedonlyAlert(false)}>Digitar</button>
            </Dialog>


            <Dialog open={openSummary} onClose={() => {
                setOpenSummary(false)
                setselected(null)
            }} className="dialogAlert">
                <>
                    <p className="titleAlert">CONFIRMAÇÃO DO SALDO DISPONÍVEL NO CARTÃO DE CRÉDITO</p>

                    {/* <p style={{ color: 'red' }}>O valor do saldo a ser utilizado em <u style={{ color: 'red' }}>seu cartão de crédito</u> é <strong>{formatCurrency(selected?.total as number)}</strong></p> */}

                    <p>Para continuarmos é importante que verifique se seu cartão de crédito está liberado para compras online.
                    </p>

                    <p>Nos confirme se o <strong>saldo disponível para compras em seu cartão de crédito</strong> é:
                    </p>

                    <div className="containerValuesMinMax">
                        <button
                            className="buttonValuesMinMax"
                            onClick={() => {
                                setOpenSummary(false)
                                setselected(null)
                                setOtherValue(true)
                                sendToShow("otherValue")
                            }}>
                            <p style={{
                                fontSize: '1.5rem',
                                color: '#fff'
                            }}>INFERIOR A:
                                <br />
                                {formatCurrency(selected?.total as number)}
                            </p>
                        </button>
                        <p className="or">ou</p>
                        <button
                            className="buttonValuesMinMax"

                            onClick={() => {
                                // nextStep()
                                amountUpdate()
                                // setCheckoutSteps(checkoutSteps + 1)
                                setOpenSummary(false)
                            }}>
                            <p style={{
                                fontSize: '1.5rem',
                                color: '#fff'
                            }}>SUPERIOR A:
                                <br />
                                {formatCurrency(selected?.total as number)}
                            </p>
                        </button>
                    </div>
                </>
            </Dialog>

            <Dialog open={openDialogTimer}>
                <div className="dialogTimer">
                    <p>{renderTimer(timerCount)}</p>
                    <p>Estamos aguardando você entrar em contato com a
                        operadora de seu cartão de crédito e solicitar a autorização para compras online no valor
                        de {formatCurrency(selected?.total as number ?? proposalAmounts?.total)}</p>
                </div>
            </Dialog>

            <Dialog open={openDialogAvoidBlocking} className="dialogAvoidBlocking">
                <>
                    <p className="titleBlockingConfirm">{clientName}</p>


                    <div className="cardAvoid">
                        <p style={{ color: 'red', margin: '0px' }}><u>PARA EVITAR O BLOQUEIO <br />DO SEU CARTÃO DE CRÉDITO</u></p>
                        <p>Apenas clique em <u>confirmo</u>, após verificar o seu
                            <strong> SALDO DISPONÍVEL PARA COMPRAS</strong>
                        </p>
                    </div>

                    <button className="buttonAvoidBlockingConfirm" onClick={() => setOpenDialogAvoidBlocking(false)}><u>CONFIRMO</u> QUE VERIFIQUEI, <br />
                        MEU SALDO DISPONÍVEL<br /> É
                        <u> MAIOR</u> QUE {formatCurrency(proposalAmounts?.total as number)}
                    </button>

                    <button className="buttonAvoidBlockingNotConfirm" onClick={() => {
                        setCheckoutSteps(1)
                        setselected(null)
                        setProgressIndicator(progressIndicator - 1)
                        setOpenDialogAvoidBlocking(false)
                    }}>
                        O meu saldo disponível <br />é menor que {formatCurrency(proposalAmounts?.total as number)} <br />
                        <u>Clique aqui para simular outro valor para seu empréstimo</u>
                    </button>

                </>

            </Dialog>

            <Dialog open={openDialogOfficeHour}>
                <DialogTitle>
                    <div style={{
                        display: 'flex',
                        flexDirection: "row",
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                        fontSize: '2.4rem',
                        flexWrap: 'wrap'
                    }}>
                        <img
                            src={WarningIcon}
                            style={{
                                minWidth: '5rem',
                                height: '4rem'
                            }} />
                        <strong>ATENÇÃO
                        </strong>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <p style={{ textAlign: 'center', marginBottom: '20px', fontSize: '2rem', textTransform: 'uppercase', fontWeight: 'bold' }}>
                        Horário para crédito <br />em sua conta bancária!
                    </p>
                    <p style={{ textAlign: 'center', marginBottom: '20px', fontSize: '2rem' }}>
                        <strong>As 09:00 horas do próximo dia útil</strong> é a previsão para depósito em sua conta bancária, devido ao horário de expediente bancário.
                    </p>

                </DialogContent>
                <DialogActions
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: '1.5rem'
                    }}
                >
                    <button
                        style={{
                            width: 'auto',
                            fontSize: '1.7rem',
                            height: 'auto',
                            backgroundColor: 'var(--green-button-color)',
                            color: 'var(--blue-text-color)',
                            padding: '1.5rem 2rem',
                            border: 'none',
                            borderRadius: '4px',
                            fontWeight: 'bold'
                        }}
                        onClick={() => {
                            setOpenDialogOfficeHour(false)
                        }}
                    >
                        CONCORDO, QUERO CONTINUAR
                    </button>
                    <button
                        style={{
                            width: 'auto',
                            fontSize: '1.7rem',
                            height: 'auto',
                            backgroundColor: '#d5d8de',
                            color: 'black',
                            padding: '1.5rem 2rem',
                            border: 'none',
                            borderRadius: '4px',
                        }}
                        onClick={() => {
                            window.open('https://ayude.com.br', '_self', '')
                        }
                        }
                    >
                        NÃO CONTINUAR
                    </button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDialogFailed3ds}>
                <DialogContent>
                    <h1 style={{
                        textAlign: "center"
                    }}>A verificação do seu cartão de crédito falhou</h1>
                    <h1 style={{
                        textAlign: "center"
                    }}>Digite os dados novamente</h1>
                </DialogContent>
                <DialogActions>
                    <button style={{
                        width: 'auto',
                        fontSize: '1.7rem',
                        height: 'auto',
                        backgroundColor: 'var(--green-button-color)',
                        color: 'var(--blue-text-color)',
                        padding: '1.5rem 2rem',
                        border: 'none',
                        borderRadius: '4px',
                        fontWeight: 'bold'
                    }} onClick={() => {
                        setOpenDialogFailed3ds(false)
                        resetProposal()
                    }}>Entendi</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openModalError}>

                <DialogContent>
                    <p style={{ textAlign: 'center', marginBottom: '20px', fontSize: '2rem', textTransform: 'uppercase', fontWeight: 'bold' }}>
                        {errorMessage.reason}
                    </p>
                    <p style={{ textAlign: 'center', marginBottom: '20px', fontSize: '2rem' }}>
                        {errorMessage.message}
                    </p>

                </DialogContent>
                <DialogActions
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: '1.5rem'
                    }}
                >
                    <button
                        style={{
                            width: 'auto',
                            fontSize: '1.7rem',
                            height: 'auto',
                            backgroundColor: 'var(--blue-text-color)',
                            color: 'white',
                            padding: '1.5rem 2rem',
                            border: 'none',
                            borderRadius: '4px',
                            fontWeight: 'bold'
                        }}
                        onClick={() => {
                            if (tries === 3) {
                                resetProposal()
                            } else {
                                setOpenModalError(false)
                            }
                        }}
                    >
                        {tries === 3 ? "ENVIAR NOVA PROPOSTA" : "ENTENDI"}
                    </button>
                </DialogActions>
            </Dialog>

            {render()}
        </>
    )
}
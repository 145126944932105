import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { InputHTMLAttributes } from 'react';
import InputMask from 'react-input-mask';
import { InputComponent } from '../input';

interface InputMaskProps extends InputHTMLAttributes<HTMLInputElement> {
  mask: string;
  label: string;
  autoDisabled?: boolean;
  icon?: ReactJSXElement;
  isCvv?: boolean

}

export function InputMaskComponent({ mask, label, value, autoDisabled = false, onChange, icon, isCvv = false, ...rest }: InputMaskProps) {
  return (
    <InputMask
      mask={mask}
      maskChar={isCvv ? "" : "_"}
      value={value}
      onChange={onChange}>
      {(() => <InputComponent icon={icon} autoDisabled={autoDisabled} label={label} {...rest} />) as any}
    </InputMask>
  )
}